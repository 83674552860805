import React, {useState, useMemo, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import {Fade, Flip} from 'react-reveal';
import Tada from 'react-reveal/Tada';
import Jump from 'react-reveal/Jump';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

const Contact = ({props, ChangeActive, sendColorbg, sendColor}) => {

  const history = useHistory();


    const color1 = "#007F8B";
    const color2 = "#988EFA";
    const URL = 'https://www.useradventure.net/'
    const [Nom, setNom] = useState('');
    const [Prenom, setPrenom] = useState('');
    const [Mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [sujet, setSujet] = useState('');
    const [msg, setMsg] = useState('');
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);

    useEffect(()=>{
      sendColorbg('white')
      sendColor("black")
      }, [props])
    

    const ChangeNom = (event) => {
        setNom(event)
        if(event !== '' && Prenom !=='' && Mail !=='' && msg !==''&& sujet !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };
      
      const ChangePrenom = (event) => {
        setPrenom(event)
        if(event !== '' && Nom !=='' && Mail !=='' && msg !==''&& sujet !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      const ChangeMail = (event) => {
        setMail(event)
        if(event !== '' && Nom !=='' && Prenom !=='' && msg !==''&& sujet !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      const ChangePhone = (event) => {
        setPhone(event)
       
       console.log(event)
      };

      const ChangeSujet = (event) => {
        setSujet(event)
        if(event !== '' && Nom !=='' && Prenom !=='' && Mail !=='' && msg !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      const ChangeMsg = (event) => {
        setMsg(event)
        if(event !== '' && Nom !=='' && Prenom !=='' && Mail !=='' && sujet !==''){
          setdisabledcolor('#febf01')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
       console.log(event)
      };

      const validCommand1 = () => {
        var position = Mail.indexOf("@");
        var reste = Mail.substr(-position);
        var posit = reste.indexOf(".");
        //alert(posit + position + Mail )
        if(position !== -1 && posit !== -1 ){
        if(disabled){
          fetch(`${URL}json_get_react_site_contact.php`, {
            method: 'post',
            credentials:'same-origin',
            body: JSON.stringify({mail:Mail,
              name:Nom,
              surname:Prenom,
              phone:phone,
              sujet:sujet,
              msg:msg
            })
          })
            .then((response) => response.json())
            .then(() => {setMsg('')
          setPhone('')
          setMail('')
          setPrenom('')
          setSujet('')
          setNom('')
          alert('Merci pour ton message !')
          history.push("/")
              
        })
            .catch((error) => {console.error(error)
              setMsg('')
              setPhone('')
              setMail('')
              setPrenom('')
              setSujet('')
              setNom('')
              alert('Merci pour ton message !')
              history.push("/")
                })
            
          }else{
            alert('Veuillez remplir tous les champs, Merci!')
          }
       
        console.log(Nom, Prenom, phone, Mail, msg )
        
      }else{
        alert('Email non valide')
      
      }
    }

    return (
        <div>
        <Container fluid style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`, paddingBottom : 30 ,paddingTop:150}}>
        <Col md={{ span: 10, offset:1  }} lg={{ span: 4, offset: 4 }} style={{marginTop:0,background: 'rgba(0, 0, 0, 0.3)',borderRadius:50}}>
        <Jump>
          <Col className="text-center" style={{paddingTop:30,color:'white', fontWeight:'bold',fontSize:30}}>
            Contact
          </Col>
          </Jump>
          <Col className="text-center" style={{color:'white', marginTop:20,fontSize:20}}>
            * Champ obligatoire
          </Col>
          <div class="form-group" style={{marginInline:20}}>
          <label class="form-label mt-4"></label>
         
                            <div class="form-floating mb-3">
                                <input type="Nom" value={Nom} onChange={e =>ChangeNom(e.target.value)} style={{borderRadius:30}} class="form-control" id="floatingInput" placeholder="name@example.com"/>
                                <label for="floatingInput">Nom *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="Prénom" value={Prenom} onChange={e =>ChangePrenom(e.target.value)} style={{borderRadius:30}} class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Prénom *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="Prénom" value={Mail} onChange={e =>ChangeMail(e.target.value)} style={{borderRadius:30}} class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Email *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="Prénom" value={phone} onChange={e =>ChangePhone(e.target.value)} style={{borderRadius:30}} class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Téléphone</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="Nom" value={sujet} onChange={e =>ChangeSujet(e.target.value)} style={{borderRadius:30}} class="form-control" id="floatingInput" placeholder="name@example.com"/>
                                <label for="floatingInput">Sujet de votre message *</label>
                            </div>
                            <div class="form-floating mb-3">
                                <textarea  rows="5" type="Prénom" value={msg} onChange={e =>ChangeMsg(e.target.value)} style={{borderRadius:30, height:150}} class="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                                <label for="floatingPrenom">Votre message *</label>
                            </div>  
                            <Tada>
                            <Row style={{justifyContent:'center',alignItems:'center',marginTop:30}}>
        <Col  className="text-center" >
       
                            <a role="button" class="btn"  onClick={()=>validCommand1()} style={{background:'transparent', color:'white'}} >
                    <Row style={{alignItems:'center'}}>
                        <Col xs="4" style={{zIndex:2}}> 
                            <Image src="https://useradventure.net/images/pigeon.svg"  style={{height:115}}/>
                        </Col>
                        <Col xs="8" style={{zIndex:1}}>
                            <Row style={{background: disabledcolor, borderRadius:30, paddingBlock:20, paddingLeft:40,paddingRight:15, justifyContent:'center',marginLeft:-30, marginTop:-30, fontWeight:'bold', fontSize:18}}>Envoyer</Row>
                        </Col>
                    </Row>
              </a> 
              </Col>
                    </Row>

              </Tada>          
                  </div>
        </Col>
        </Container>
       
        </div>
    )
}

export default Contact
