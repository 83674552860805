import React, {useState, useMemo, useEffect} from 'react';
import {Fade, Flip, Slide} from 'react-reveal';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import Wave from 'react-wavify'
import {LinkContainer} from 'react-router-bootstrap';
import { FiArrowRightCircle } from "react-icons/fi";
import fabien from '../src/images/fabien.png';
import maxime from '../src/images/maxime.jpeg';
import julien from '../src/images/julien.png';

const Nous = ({setShow, props, ChangeActive, sendColorbg, sendColor}) => {

    const color1 = "#007F8B";
    const color2 = "#988EFA";
    const [Mail, setMail] = useState('');
    const URL = 'https://www.useradventure.net/'
    console.log(Mail)

    useEffect(()=>{
      sendColorbg('white')
      sendColor("black")
      }, [props])

const valider = () => {
  var position = Mail.indexOf("@");
  var reste = Mail.substr(-position);
  var posit = reste.indexOf(".");
  //alert(posit)
  if(position !== -1 && posit !== -1 ){
    fetch(`${URL}json_get_react_register_newsletter.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({mail:Mail})
    })
      .then((response) => response.json())
      .then(() => {alert('Merci pour ton inscription à la newsletter !')
      setMail('')})
      .catch((error) => console.error(error))
}else{
  alert('Email non valide')

}}
   

return (
        <div>
        
        <Container style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`, height:400 ,paddingTop:150}} fluid>
            <h1 style={{color:'white', textAlign:'center', paddingTop:60}}>Qui sommes-nous ?</h1>
            
        </Container>
        <div style={{marginTop:-150}}>
        <Wave fill='white' style={{paddingInline:0, marginInline:0}} 
        paused={false}
        options={{
          height: 40,
          amplitude: 70,
          speed: 0.2,
          points: 2
        }}
  />
  <Container style={{marginBlock:40}}   fluid>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 30,color: '#007F8B',paddingInline:30, fontWeight:'bold'}}>UserAdventure, une communauté bienveillante</Col>
      </Fade>
      <Fade bottom> 
      <Col lg={{offset:1,span:10}} className="text-center text-lg-start" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:60}}>Fondée en 2020, UserUdventure, basée à Lille, oeuvre afin d'améliorer le quotidien de milliards d'utilisateurs et consommateurs dans le monde (et le tien par la même occasion).</Col>
      </Fade>
      <Fade bottom> 
      <Col lg={{offset:1,span:10}} className="text-center text-lg-start" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:60}}>Notre concept est simple : avec UserAdventure, tu apportes ton avis et ton expérience afin d'améliorer la facilité d'utilisation et la qualité des produits et services disponibles sur le marché et d'éviter un maximum les problèmes d'utilisation.</Col>
      </Fade>
      <Fade bottom> 
      <Col lg={{offset:1,span:10}} className="text-center text-lg-start" style={{fontSize: 20,color: 'grey',paddingInline:30,marginTop:60}}>UserAdventure, c'est aussi une application ludique et responsable qui te permet de réaliser des missions, gagner de l'argent et soutenir des initiatives et associations de ton choix.</Col>
      </Fade>
      <Fade bottom> 
        <Col className="text-center" style={{fontSize: 30,color: '#007F8B',paddingInline:30, fontWeight:'bold',marginTop:60}}>Notre équipe</Col>
      </Fade>
      <Fade bottom> 
      <Row className="justify-content-md-center" style={{marginTop:20, paddingBottom:0}}>
      
      <Col lg={{span:1, offset: -1 }} md={{ span:8 }} style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
      <ListGroup>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><Image style={{height:100, marginTop:30, borderRadius:50}} className="img-carre" src={julien} /></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'#007F8B', fontWeight:'bold', fontSize:26}}>Julien</ListGroup.Item>
      </ListGroup>
      </Col>
      <Col lg={{span:1, offset: 1 }} md={{ span:8 }} style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
      <ListGroup>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><Image style={{height:100, marginTop:30, borderRadius:50}} className="img-carre" src={fabien} /></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'#007F8B', fontWeight:'bold', fontSize:26}}>Fabien</ListGroup.Item>
      </ListGroup>
      </Col>
     <Col lg={{span:1, offset: 1 }} md={{ span:8 }}  style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
     <ListGroup>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><Image style={{height:100, marginTop:30, borderRadius:50}} className="img-carre" src={maxime} /></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'#007F8B', fontWeight:'bold', fontSize:26}}>Maxime</ListGroup.Item>
      </ListGroup>
     </Col>
    </Row>
    </Fade>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 20,color: 'grey',paddingInline:30, fontWeight:'bold',marginTop:60, fontStyle:'italic'}}>"Nous avons créé UserAdventure pour pemettre à tous les consommateurs et utilisateurs de s'exprimer et d'améliorer le quotidien"</Col>
      </Fade>
    </Container>
    <Container  fluid>
    <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/inscription')  
                 }}   exact to={{ pathname:"/inscription", state:{ version: '1' }}}> 
      <Row style={{justifyContent:'center',alignItems:'center'}}>
        <Col  className="text-center" style={{marginTop:0}}>
              <a role="button" class="btn"  onClick={setShow} style={{background:'transparent', color:'white'}} >
                    <Row className="list" style={{alignItems:'center'}}>
                        <Col xs="4" style={{zIndex:2}}> 
                            <Image src="https://useradventure.net/images/pigeon.svg"  style={{height:115}}/>
                        </Col>
                        <Col xs="8" style={{zIndex:1}}>
                            <Row style={{background: '#febf01', borderRadius:30, paddingBlock:20, paddingLeft:40,paddingRight:15, justifyContent:'center',marginLeft:-60, marginTop:-30, fontWeight:'bold', fontSize:18}}>Commencer l'aventure</Row>
                        </Col>
                    </Row>
              </a> 
        </Col>
      </Row>
      </LinkContainer>
    </Container>
    <Container style={{backgroundColor:'rgba(211,211,211,0.3)', height:1}}  fluid></Container>
    <Container style={{marginBlock:80}}   fluid>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 20,color: 'grey',paddingInline:30, fontWeight:'bold'}}>Découvrez les actualités et restez informé</Col>
      </Fade>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 24,color: 'black',paddingInline:30, fontWeight:'bold',marginTop:30}}>Inscrivez-vous à la newsletter</Col>
      </Fade>
      <Fade bottom> 
      <Row style={{justifyContent:'center',alignItems:'center'}}>
      <Col lg="5" style={{fontSize: 24,color: 'black',paddingInline:30, fontWeight:'bold',marginTop:30}}>
      <InputGroup   onChange={e => setMail(e.target.value)}>
    <FormControl
      placeholder="Votre email"
      aria-label="Recipient's username"
      aria-describedby="basic-addon2"
      value={Mail}
    />
    <Button variant="outline-secondary" id="button-addon2" onClick={()=>valider()}>
    <FiArrowRightCircle style={{cursor:'pointer', fontSize:26}}/> Valider
    </Button>
  </InputGroup>
  </Col>
  </Row>
      </Fade>
    </Container>
</div>


        </div>
    )
}

export default Nous
