import React, {useState, useMemo, useEffect, useRef} from 'react';
import './App.css';
import {LinkContainer} from 'react-router-bootstrap';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup , Button, Alert } from 'react-bootstrap';
import ampoule from './images/ampoule.png';
import partager from './images/partager.png';
import argent from './images/argent.jpg';
import indem from './images/u844.png';
import temps from './images/u84.png';
import lieu from './images/u85.png';
import {Fade, Flip} from 'react-reveal';
import pauline from './images/pauline.png';
import delphine from './images/delphine.JPG';
import jpa from './images/jpa.png';
import groupe from './images/u113groupe.png';
import Jump from 'react-reveal/Jump';
import Tada from 'react-reveal/Tada';
import Pulse from 'react-reveal/Pulse';
import countryList from 'react-select-country-list'
import { BsFillCaretLeftFill, BsFacebook, BsInstagram, BsTwitter , BsYoutube} from "react-icons/bs";
import { GiEasterEgg } from "react-icons/gi";
import { RiDoubleQuotesR } from "react-icons/ri";
import resto from './images/Logo_Restos.jpeg';
import Secours from './images/Secours_p.png';
import emmaus from './images/EMMAUS.jpeg';
import zevent from './images/Zevent.png';
import u74 from './images/u74.png';
import imgtest1 from './images/unnamed.png';
import "react-datepicker/dist/react-datepicker.css";
import Wave from 'react-wavify'
import Nav from 'react-bootstrap/Nav';
import Carousel from 'react-bootstrap/Carousel';
import {BrowserView, MobileView, isMobile, isIOS, isAndroid} from 'react-device-detect';
import Modal1 from '../src/components/Modal1'
import Modal2 from '../src/components/Modal2'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, useWindowDimensions} from 'react-native';

const Home = ({show, setShow, setShow3, setOeuf, ChangeActive, colorbg, color,sendColorbg, sendColor}) => {

const color1 = "#007F8B";
const color2 = "#988EFA";
const [DataEtude, setDataEtude] = useState([]);
const history = useHistory();
const largeur = window.innerWidth;
//const { overwrite, getName } = require('country-list');
const { height, width } = useWindowDimensions();
//const [show, setShow] = useState(false);
//const [show1, setShow1] = useState(false);
const [affiche, setaffiche] = useState(false);

const [showButton, setShowButton] = useState(true);
const [showMessage, setShowMessage] = useState(false);
const nodeRef = useRef(null);

useEffect(()=>{
  sendColorbg('white')
  sendColor("black")
  fetch('https://www.useradventure.net/json_get_react_futur_test.php')
  .then((response) => response.json())
  .then((response) => { 
setDataEtude(response)
console.log(response)
  })
  .catch((error) => alert(error))
  
  }, [])


const Video = () => {
  setaffiche(true)
}

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  1050: { items: 2 },
  1599: { items: 3 },
};

  return (
    <>
    <div id="top"></div>
    <Container style={{background: `linear-gradient(to bottom,  ${color1} 40%,${color2} 90%)`, paddingBottom:50, paddingTop:100}} fluid>
      <Row style={{justifyContent:'center',alignItems:'center', paddingTop:60, paddingBottom:90}}>
            <Col lg="6" style={{paddingInline:30, paddingBlock:30}}>
                <Row >
                  
                  <Col lg={{span: 10, offset: 0 }} xxl={{span: 7, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
                   
                    <h1  style={{color:'white', fontWeight:'bold' ,marginBottom:20,lineHeight:1.2,}}>Passe de consommateur à conso'Acteur</h1>
                    <h3  style={{color:'white', fontWeight:'bold' ,marginBottom:20,lineHeight:1.2,}}>Participe à des sondages et études rémunérés pour changer le monde et gagner de l'argent.</h3>
                    <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/inscription')  
                 }}   exact to={{ pathname:"/inscription", state:{ version: '1' }}}> 
                 
                 <Row style={{justifyContent:'center',alignItems:'center'}}>
      
        <Col style={{marginTop:0}}>
       
                    <a role="button" class="btn" id="start"  onClick={setShow} style={{background:'transparent', color:'white'}} >
                    <Row className="list" style={{alignItems:'center'}}>
                        <Col xs="4" style={{zIndex:2}}> 
                            <Image src={u74}  style={{height:115}}/>
                        </Col>
                        <Col xs="8" style={{zIndex:1}}>
                            <Row style={{background: '#febf01', borderRadius:35, paddingBlock:20, paddingLeft:50,paddingRight:25, justifyContent:'center',marginLeft:-50, marginTop:-30, fontWeight:'bold', fontSize:18}}><span style={{color: '#007F8B'}}>Inscription</span></Row>
                        </Col>
                    </Row>
              </a> 
              </Col> 
      </Row>
      </LinkContainer>
                  </Col>
                </Row>       
            </Col>
            <Col lg="6" style={{justifyContent:'center', paddingBlock:60}}>
            <Container style={{ paddingTop: '2rem' }}>
      {showButton && (
        <Image src={groupe} fluid style={{ cursor:'pointer', padding:60}} onClick={() => setShowMessage(true)}/>
      )}
      <CSSTransition
        in={showMessage}
        nodeRef={nodeRef}
        timeout={300}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowButton(false)}
        onExited={() => setShowButton(true)}
      >
        <div className="videop">
                  <Ratio aspectRatio="16x9">
                      <iframe  style={{ cursor:'pointer'}}  title="animation useradventure"  className="rounded" src="https://www.youtube.com/embed/-OPvO76gb0s?rel=0&loop=1&autoplay=1&mute=1" frameborder= "0" allow="autoplay ; fullscreen" allowfullscreen></iframe>
                  </Ratio>
              </div>
      </CSSTransition>
    </Container>
        
            </Col>
      </Row>
    </Container>
    <div style={{marginTop:-150}}>
        <Wave fill='white' style={{paddingInline:0, marginInline:0}} 
        paused={false}
        options={{
          height: 40,
          amplitude: 90,
          speed: 0.2,
          points: 2
        }}/>
        </div>
    {/* <Container  fluid>
      <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/inscription')  
                 }}   exact to={{ pathname:"/inscription", state:{ version: '1' }}}> 
                 
                 <Row style={{justifyContent:'center',alignItems:'center'}}>
      
        <Col  className="text-center" style={{marginTop:-50}}>
       
              <a role="button" class="btn" id="start"  onClick={setShow} style={{background:'transparent', color:'white'}} >
                    <Row className="list" style={{alignItems:'center'}}>
                        <Col xs="4" style={{zIndex:2}}> 
                            <Image src="https://useradventure.net/images/pigeon.svg"  style={{height:115}}/>
                        </Col>
                        <Col xs="8" style={{zIndex:1}}>
                            <Row style={{background: '#febf01', borderRadius:30, paddingBlock:20, paddingLeft:40,paddingRight:15, justifyContent:'center',marginLeft:-40, marginTop:-30, fontWeight:'bold', fontSize:18}}>Inscris-toi</Row>
                        </Col>
                    </Row>
              </a> 
             
        </Col> 
      </Row>
      </LinkContainer>
    </Container> */}

    <Container fluid style={{marginTop:0, marginBottom:120}}>
    <Col className="text-center" style={{marginTop:40, marginBottom:60,fontSize: 30,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>Découvre les études à venir !</Col>
    {/* <Col lg={{ offset: 1, span:10 }} className="text-center text-lg-start"  >
   
    <Row xs={1} md={2} className="gx-4 justify-content-md-center" style={{alignItems:'center'}}>
    <Carousel variant="dark">
    { DataEtude && DataEtude.map((item,index) => ( 
      item.Etat === '0' ? <Carousel.Item interval={5000}>
      <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/test')  
                 }}   exact to={{ pathname:"/test", state:{ version: '1' }}}>
                  <Nav.Link>
                  <Col className="text-center"  style={{paddingInline:0, backgroundColor:'white', borderRadius:15, boxShadow:  '5px 5px 5px rgb(0 127 139/ 99%)'}}>
   
   <Row style={{marginTop:0, padding:0}}>
     <Col xxl={5}>
     <Image src={item.Image} fluid  style={{ padding:0, justifySelf:'center',borderRadius:11}}/>
     </Col>
     <Col xxl={7} style={{ paddingBlock:width > 1890 ? 20: width > 1550 ? 15:10,paddingInline:0}}>
     <Col className="text-center text-xxl-start" style={{fontSize: width > 1890 ? 18:width > 1550 ? 16:14,color: '#007F8B', fontWeight:'bold'}}>{item.Titre}</Col>
     <Col className="text-center text-xxl-start" style={{fontSize: width > 1890 ? 14: width > 1550 ? 13:12, marginTop:width > 1890 ? 15:10,color: 'grey'}}>Rémunération : <span style={{fontSize: width > 1890 ? 14:width > 1550 ? 13:12,color: '#007F8B', fontWeight:'bold'}}>{item.Renumeration} €</span></Col>
   <Col className="text-center text-xxl-start" style={{fontSize: width > 1890 ? 14: width > 1550 ? 13:12,color: 'grey'}}>Temps de l'étude : <span style={{fontSize: width > 1890 ? 14:width > 1550 ? 13:12,color: '#007F8B', fontWeight:'bold'}}>{item.Temps}</span></Col>
   <Col className="text-center text-xxl-start" style={{fontSize: width > 1890 ? 14: width > 1550 ? 13:12, marginBottom:width > 1890 ? 20:10,color: 'grey'}}>Période estimée : <span style={{fontSize: width > 1890 ? 14:width > 1550 ? 13:12,color: '#007F8B', fontWeight:'bold'}}>{item.Periode}</span></Col>
     </Col>
   </Row>
   
   </Col> 
      </Nav.Link>
                </LinkContainer></Carousel.Item> : undefined
      ))}
    </Carousel>
    </Row>
</Col> */}
 <Col lg={{ offset: 4, span:4 }} className="text-center text-lg-start"  >
   
  
    
   <Carousel>
     
      {DataEtude.filter(item => item.Etat === '0').map(item => (  
         
        <Carousel.Item>
   <div class="card"  style={{ borderRadius:15, boxShadow:  '0px 5px 5px rgb(0 127 139/ 99%)', marginBottom:20}}>
  <img src={item.Image} style={{ borderRadius:15, position:'relative'}} class="card-img-top" alt="Test utilisateur" onDragStart={handleDragStart} role="presentation"/>
  <div  class="bg-light" style={{ height: '35%',position:'absolute',bottom:0,borderBottomRightRadius:15,borderBottomLeftRadius:15,padding:15}}>
  <div style={{ height: '30%'}} class="row align-items-start">
    <p class="card-title" style={{color: '#21535A', fontSize:  16, fontWeight:'bold'}}>{item.Titre}</p>
    <p class="card-text" style={{color: '#357E8A', fontSize:  14}}>{item.Text1}</p>
    </div>
    <div style={{marginTop:45}}  class="container text-center">
  <div style={{ height: '30%'}} class="row align-items-start">
    <div class="col">
    <Image src={indem} style={{ height: 25, width: 30}} class="img-fluid" alt="Test utilisateur"/>
    </div>
    <div class="col">
    <Image src={temps} style={{ height: 25, width: 25}} class="img-fluid" alt="Test utilisateur"/>
    </div>
    <div class="col">
    <Image src={lieu}  style={{ height: 25, width: 25}} class="img-fluid" alt="Test utilisateur"/>
    </div>
  </div>
  <div style={{ height: '30%',marginTop:3 }} class="row align-items-start">
    <div class="col">
    <span style={{fontSize: 14,color: '#007F8B', fontWeight:'bold'}}>{item.Renumeration} €</span>
    </div>
    <div class="col">
    <span style={{fontSize: 14,color: '#007F8B', fontWeight:'bold'}}>{item.Temps}</span>
    </div>
    <div class="col">
    <span style={{fontSize: 14,color: '#007F8B', fontWeight:'bold'}}>{item.Periode}</span>
    </div>
  </div>
</div>
  </div>
</div> 
</Carousel.Item>
 ) )}
     
    </Carousel> 
   

  
</Col>
    </Container>

    {/* <Container fluid style={{marginTop:0, marginBottom:120}}>
    <Col className="text-center" style={{marginTop:40, marginBottom:60,fontSize: 30,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>Découvre les études à venir !</Col>
    <Col lg={{ offset: 1, span:10 }} className="text-center text-lg-start"  >
   
    <Row xs={1} md={3} className="gx-4 justify-content-md-center" style={{alignItems:'center'}}>
    { DataEtude && DataEtude.map((item,index) => ( 
      item.Etat === '0' ? <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/test')  
                 }}   exact to={{ pathname:"/test", state:{ version: '1' }}}>
                  <Nav.Link>
                  <Col className="text-center"  style={{paddingInline:0, backgroundColor:'white', borderRadius:15, boxShadow:  '5px 5px 20px rgb(0 127 139/ 99%)'}}>
   
   <Row style={{marginTop:0, padding:0}}>
     <Col xxl={5}>
     <Image src={item.Image} fluid  style={{ padding:0, justifySelf:'center',borderRadius:11}}/>
     </Col>
     <Col xxl={7} style={{ paddingBlock:width > 1890 ? 20: width > 1550 ? 15:10,paddingInline:0}}>
     <Col className="text-center text-xxl-start" style={{fontSize: width > 1890 ? 18:width > 1550 ? 16:14,color: '#007F8B', fontWeight:'bold'}}>{item.Titre}</Col>
     <Col className="text-center text-xxl-start" style={{fontSize: width > 1890 ? 14: width > 1550 ? 13:12, marginTop:width > 1890 ? 15:10,color: 'grey'}}>Rémunération : <span style={{fontSize: width > 1890 ? 14:width > 1550 ? 13:12,color: '#007F8B', fontWeight:'bold'}}>{item.Renumeration} €</span></Col>
   <Col className="text-center text-xxl-start" style={{fontSize: width > 1890 ? 14: width > 1550 ? 13:12,color: 'grey'}}>Temps de l'étude : <span style={{fontSize: width > 1890 ? 14:width > 1550 ? 13:12,color: '#007F8B', fontWeight:'bold'}}>{item.Temps}</span></Col>
   <Col className="text-center text-xxl-start" style={{fontSize: width > 1890 ? 14: width > 1550 ? 13:12, marginBottom:width > 1890 ? 20:10,color: 'grey'}}>Période estimée : <span style={{fontSize: width > 1890 ? 14:width > 1550 ? 13:12,color: '#007F8B', fontWeight:'bold'}}>{item.Periode}</span></Col>
     </Col>
   </Row>
   
   </Col> 
      </Nav.Link>
                </LinkContainer> : undefined
      ))}
      </Row>

</Col>
 </Container> */}
    
 
    <Container style={{marginTop:80, marginBottom:50}}   fluid>

      <Col lg={{ offset: 1 }} className="text-center text-lg-start"  style={{fontSize: 30,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>Top 3 des raisons de rejoindre l'aventure
      <Col className="bar"  style={{background: '#febf01', borderRadius:6}}></Col></Col>
    </Container>
<Container   fluid>
 
 <Row className="justify-content-center" style={{marginTop:0, paddingBottom:0}}>
    <Col className="share" lg={{span:2, offset: -1 }} md={{ span:8 }} xs={{span:8}} style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
      <ListGroup>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><span style={{fontSize: 28,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>Découvre</span></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent',display:'flex', justifyContent:'center',alignItems:'center',  color:'white', fontWeight:'bold', fontSize:26}}>
      <row  className="radius"  style={{display:'flex', justifyContent:'center',alignItems:'center', height:200, width:270}}>
                              <Pulse>
                                <Image src={ampoule}  style={{height:115}} alt="Test utilisateur"/>
                              </Pulse>
      </row></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20, minHeight:110}}><span style={{paddingBlock:15,color: 'grey'}}><span style={{color: 'grey', fontWeight:'bold'}}>Découvre</span> des nouveaux produits, services et innovations</span></ListGroup.Item>
      <ListGroup.Item className="text-center badge" style={{borderWidth:0, fontSize:46, backgroundColor:'transparent', color:'white'}}>
      <Col style={{display:'inline-block',justifyContent:'center',alignItems:'center'}}>
                        <Col style={{backgroundColor:'#febf01',display:'flex', justifyContent:'center',alignItems:'center', height:50, width:50, borderRadius:25, color:'white',fontSize:30, fontWeight:'bold'}}>1</Col>
                        </Col>
      </ListGroup.Item>
    </ListGroup>
     
    </Col>
    <Col lg={{span:2, offset: 1 }} md={{ span:8 }} xs={{span:8}} style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
      <ListGroup>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><span style={{fontSize: 28,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>Partage</span></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent',display:'flex', justifyContent:'center',alignItems:'center',  color:'white', fontWeight:'bold', fontSize:26}}>
      <row  className="radius"  style={{display:'flex', justifyContent:'center',alignItems:'center', height:200, width:270}}>
                              <Pulse>
                                <Image src={partager}  style={{height:115}} alt="Test utilisateur"/>
                              </Pulse>
      </row></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20, minHeight:110}}><span style={{paddingBlock:15,color: 'grey'}}><span style={{color: 'grey', fontWeight:'bold'}}>Partage</span> ton expérience et apporte ta lumière</span></ListGroup.Item>
      <ListGroup.Item className="text-center badge" style={{borderWidth:0, fontSize:46, backgroundColor:'transparent', color:'white'}}>
      <Col style={{display:'inline-block',justifyContent:'center',alignItems:'center'}}>
                        <Col style={{backgroundColor:'#febf01',display:'flex', justifyContent:'center',alignItems:'center', height:50, width:50, borderRadius:25, color:'white',fontSize:30, fontWeight:'bold'}}>2</Col>
                        </Col>
      </ListGroup.Item>
    </ListGroup>
     
    </Col>
    <Col className="share" lg={{span:2, offset: 1 }} md={{ span:7 }} xs={{span:8}} style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
      <ListGroup>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><span style={{fontSize: 28,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>Gagne</span></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent',display:'flex', justifyContent:'center',alignItems:'center',  color:'white', fontWeight:'bold', fontSize:26}}>
      <row  className="radius"  style={{display:'flex', justifyContent:'center',alignItems:'center', height:200, width:270}}>
                              <Pulse>
                                <Image src={argent}  style={{height:115}} alt="Test utilisateur"/>
                              </Pulse>
      </row></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20, minHeight:110}}><span style={{paddingBlock:15,color: 'grey'}}><span style={{color: 'grey', fontWeight:'bold'}}>Gagne</span> de l'argent en réalisant des missions</span></ListGroup.Item>
      <ListGroup.Item className="text-center badge" style={{borderWidth:0, fontSize:46, backgroundColor:'transparent', color:'white'}}>
      <Col style={{display:'inline-block',justifyContent:'center',alignItems:'center'}}>
                        <Col  style={{backgroundColor:'#febf01',display:'flex', justifyContent:'center',alignItems:'center', height:50, width:50, borderRadius:25, color:'white',fontSize:30, fontWeight:'bold'}}>3</Col>
                        </Col>
      </ListGroup.Item>
    </ListGroup>
     
    </Col>
  </Row>
 </Container>
    <Container style={{marginTop:-70}}  fluid>
    {/* <Row className="justify-content-md-center" style={{marginTop:0}}>
    <Col md={{span:6}} lg="6" style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
    <Fade bottom>
    <Image src={groupe}  fluid/>
    </Fade>
    </Col>
    </Row> */}
    </Container>
      <Container style={{marginTop:80, marginBottom:60}}   fluid>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 30,color: '#007F8B',paddingInline:30, fontWeight:'bold'}}>Rejoins une communauté bienveillante</Col>
      </Fade>
      <Fade bottom> 
      <Col className="text-center" style={{fontSize: 24,color: 'grey',paddingInline:30, fontWeight:'bold',marginTop:60}}>En utilisant UserAdventure, tu permets d'améliorer le quotidien de milliards d'utilisateurs et consommateurs dans le monde</Col>
      </Fade>
      {/* <Fade bottom> 
        <Col className="text-center" style={{fontSize: 30,color: '#febf01',paddingInline:30, fontWeight:'bold',marginTop:60}}>Soutiens de nombreuses initiatives et associations</Col>
      </Fade> */}
    </Container>

    {/* <Container style={{marginTop:80}}  >
    <Row className="justify-content-md-center">
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
      <Image src={zevent}  style={{height:150, padding:40}} alt="Test utilisateur"/>
    </Col>
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={resto}  style={{height:150}} alt="Test utilisateur"/>
    </Col>
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={Secours}  style={{height:150}} alt="Test utilisateur"/>
    </Col>
    <Col md="3" className="fond_image" style={{display:'flex', justifyContent:'center',alignItems:'center'}} >
      <Image src={emmaus}  style={{height:150, padding:10, justifySelf:'center'}} alt="Test utilisateur"/>
    </Col>
    
  
  </Row>
  
    </Container> */}
   
   
   
   
<Wave fill='#febf01' style={{padding:0}} 
        paused={false}
        options={{
          height: 40,
          amplitude: 70,
          speed: 0.2,
          points: 2
        }}
  />
 
 <Container style={{backgroundColor:'#febf01'}}   fluid>
 <Col lg={{ offset: 1 }} className="text-center text-lg-start" style={{fontSize: 30,color: 'white', fontWeight:'bold'}}>Ils ont commencé une belle aventure avec nous
 <Col className="bar"  style={{background: '#007F8B', borderRadius:6}}></Col></Col>
 
 <Row className="justify-content-md-center" style={{marginTop:80, paddingBottom:100}}>
    <Col lg={{span:2, offset: -1 }} md={{ span:8 }} style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
      <ListGroup style={{ paddingBottom:0,background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`}}>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><Image style={{height:80, marginTop:30,borderRadius:40}} src={delphine} alt="Test utilisateur"/></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontWeight:'bold', fontSize:26}}>Delphine</ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20}}>Responsable</ListGroup.Item>
      <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20}}>Je suis adepte des études en ligne et j'y trouve mon compte sur UserAdventure. j'ai hâte de découvrir l'application pour aller plus loin.</ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, fontSize:46, backgroundColor:'transparent', color:'white'}}><RiDoubleQuotesR/></ListGroup.Item>
    </ListGroup>
     
    </Col>
    <Col lg={{span:2, offset: 1 }} md={{ span:8 }}  style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
      <ListGroup style={{ paddingBottom:0,background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`}}>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><Image style={{height:80, marginTop:30,borderRadius:40}}  src={jpa} alt="Test utilisateur"/></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontWeight:'bold', fontSize:26}}>Jean-Paul</ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20}}>Retraité</ListGroup.Item>
      <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20, textAlign:'justify'}}>Etant à la retraite, j'ai pas mal de temps libre. C'est agréable de rencontrer de nouvelles personnes et de découvrir de nouveaux produits et ça me fait un petit complément.</ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, fontSize:46, backgroundColor:'transparent', color:'white'}}><RiDoubleQuotesR/></ListGroup.Item>
    </ListGroup>
     
    </Col>
    <Col lg={{span:2, offset: 1 }} md={{ span:8 }} style={{display:'flex', justifyContent:'center',alignItems:'center', marginBottom:30}}>
      <ListGroup style={{ paddingBottom:0,background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`}}>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent'}}><Image style={{height:80, marginTop:30}}  src={pauline} alt="Test utilisateur"/></ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontWeight:'bold', fontSize:26}}>Pauline</ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20}}>UX Designer</ListGroup.Item>
      <ListGroup.Item style={{borderWidth:0, backgroundColor:'transparent', color:'white', fontSize:20, textAlign:'justify'}}>Avec UserAdventure, j'ai pu tester en avant première des innovations et soutenir des associations et projets d'innovation! le top !.</ListGroup.Item>
      <ListGroup.Item className="text-center" style={{borderWidth:0, fontSize:46, backgroundColor:'transparent', color:'white'}}><RiDoubleQuotesR/></ListGroup.Item>
    </ListGroup>
     
    </Col>
  </Row>
 </Container>
 <Container  fluid>
 <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('/inscription')  
                 }}   exact to={{ pathname:"/inscription", state:{ version: '1' }}}> 
      <Row style={{justifyContent:'center',alignItems:'center'}}>
        <Col  className="text-center" style={{marginTop:-50}}>
              <a role="button" class="btn"  onClick={setShow} style={{background:'transparent', color:'white'}} >
                    <Row className="list" style={{alignItems:'center'}}>
                        <Col xs="4" style={{zIndex:2}}> 
                            <Image src="https://useradventure.net/images/pigeon.svg" alt="Test utilisateur" style={{height:115}}/>
                        </Col>
                        <Col xs="8" style={{zIndex:1}}>
                            <Row style={{background: 'white', borderRadius:30, paddingBlock:20, paddingLeft:40,paddingRight:15, justifyContent:'center',marginLeft:-40, marginTop:-30, fontWeight:'bold', fontSize:18,color: '#007F8B'}}>Inscris-toi</Row>
                        </Col>
                    </Row>
              </a> 
        </Col>
      </Row>
      </LinkContainer>
    </Container>
    <Container style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`,marginTop:-80, paddingTop:80, paddingBottom:80}} fluid>
    <Col lg={{ offset: 1 }} className="text-center text-lg-start"  style={{fontSize: 30,color: 'white',paddingInline:0, fontWeight:'bold'}}>Quelques questions ?
    <Col className="bar"  style={{background: '#febf01' ,borderRadius:6}}></Col></Col>
    <Row className="justify-content-md-center" style={{marginTop:80}}>
    <Col md="10">
    <Accordion >
  <Accordion.Item eventKey="0">
    <Accordion.Header ><span style={{fontWeight:500, fontSize:18}}>Comment rejoindre l'aventure ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Pour rejoindre l’aventure, il vous suffit de cliquer sur le bouton "commencer l’aventure" et de renseigner quelques informations sur vous. Et voilà, c'est aussi simple que cela !</p>
    <p>Dès que l’application sera disponible, nous vous enverrons vos identifiants de connexion ainsi que le lien de téléchargement de l’application.</p>
    <p>Vous êtes sur Android ou IOS? Aucun souci, notre application sera compatible avec les deux plateformes !</p>
    En cas de doute ou de question, n'hésitez pas à nous contacter via le <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('#/contact')}} style={{margin:0, padding:0}}  exact to={{ pathname:"/contact", state:{ version: '1' }}}>
                  <a>formulaire de contact</a>
                </LinkContainer>. 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Pourquoi UserAdventure ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Nous ne sommes pas une entreprise de panel consommateur comme les autres ;)</p>
    <p>Nous sommes avant tout une communauté d’utilisateurs et de consommateurs engagés dans l’amélioration du monde qui nous entoure.</p>
    C’est pour cela que nous vous proposons une nouvelle expérience et de partir à l’aventure avec nous via notre superbe application mobile. On te laisse la surprise, mais nous avons pensé notre application comme un jeu, et on espère que tu vas apprécier :)
</Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>C’est quoi ce pigeon ?</span></Accordion.Header>
    <Accordion.Body>
    <p>C’est ton nouveau meilleur ami ! Pigeon voyageur sera toujours là pour te guider et t’aider dans ton aventure. C’est lui qui fera le lien entre toi et les membres de UserAdventure.</p>
Son plus grand talent sera aussi de t’offrir des petits cadeaux de temps en temps. Juste comme ça, parce qu’il a le cœur sur l’aile. 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Pourquoi devenir aventurier ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Il y a plein de bonnes raisons pour rejoindre l’aventure :</p>
<p style={{display:'block'}}><span style={{fontWeight:'bold',color: '#007F8B'}}>Rejoins une communauté engagée et bienveillante :</span> Le partage est au centre des valeurs de UserAdventure. Notre objectif commun est d'œuvrer afin d’améliorer le quotidien de milliards d’utilisateurs et de consommateurs dans le monde (et le tien par la même occasion). En plus, on peut même soutenir des associations et projets innovants, elle est pas belle la vie ?</p>
<p style={{display:'block'}}><span style={{fontWeight:'bold',color: '#007F8B'}}>Découvre les produits et innovations de demain :</span> Avec nous, tu pourras découvrir de nouveaux produits et services et même être l’un des premiers à tester l’une des prochaines innovations mondiales !</p>
<p style={{display:'block'}}><span style={{fontWeight:'bold',color: '#007F8B'}}>Partage ton expérience et apporte ta lumière :</span> Ton vécu, ton expérience et ta voix compte plus que jamais pour faire bouger les choses ! Nous aidons les entreprises à accéder aux avis et retours d’expériences de leurs utilisateurs et nous les accompagnons pour faire évoluer leurs produits et services dans le bon sens :)</p>
<p style={{display:'block'}}><span style={{fontWeight:'bold',color: '#007F8B'}}>Protège le monde des problèmes d’utilisation :</span> Nous avons tous connu de grands moments de frustration ou une expérience malheureuse lors de l’utilisation d’un produit ou d’un service. Parfois, c’est juste énervant sur le coup, mais parfois, ça peut être bien plus grave… Déclarer ces mauvaises expériences dans l’application UserAdventure nous permettra de quantifier et d’analyser ces différents problèmes afin de les faire remonter aux entreprises et les aider à corriger tout ça !</p>
<p style={{display:'block'}}><span style={{fontWeight:'bold',color: '#007F8B'}}>Gagne de l’argent et des récompenses :</span> Et oui, en plus de tout ça, tu gagnes aussi de l’argent et des récompenses ! Ton temps est précieux, alors nous ne sommes pas là pour l’exploiter sans contrepartie.</p>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="4">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Qu’est ce que je gagne ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Si tu aimes les cadeaux, bouge pas, car on a tendance à être généreux ici :</p>
    <p><span style={{fontWeight:'bold',color: '#007F8B'}}>Des shields (ta monnaie virtuelle):</span> Pas d’arnaque, 1 Shield = 1 euros. Tu peux décider de tout garder pour toi ou de faire don de quelques Shields à des associations ou projets d’innovation.</p>
    <p><span style={{fontWeight:'bold',color: '#007F8B'}}>Des points :</span> Les points permettront de progresser dans l’aventure mais aussi de gagner des cadeaux : Carte cadeaux, goodies, objets et autres petites surprises…</p>
    <p><span style={{fontWeight:'bold',color: '#007F8B'}}>Des produits :</span> Il pourra t’arriver de tester des produits à la maison. Dans ce cas, tu sera livré d’une quantité de produits que tu pourras conserver.</p>
    <p><span style={{fontWeight:'bold',color: '#007F8B'}}>Des cadeaux:</span> ça reste entre nous, mais on à tendance à offrir des cadeaux ;) </p>
Notre reconnaissance éternelle 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="5">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>C’est quoi une étude utilisateur/consommateur ?</span></Accordion.Header>
    <Accordion.Body>
    <p>Les études permettent aux entreprises de confronter leur produit ou service à la réalité des utilisateurs et consommateurs. L'objectif est de comprendre les utilisateurs, leurs besoins, leurs avis et de savoir comment ils se servent de leur produit et les difficultés qu'ils peuvent rencontrer.</p>
<p>Pas de panique ! Il n’y a pas de bonnes ou de mauvaises réponses, nous ne sommes pas là pour t’évaluer, mais bien pour évaluer le produit ou le service ;) </p>
<p>Il existe différents formats d’études utilisateurs : à distance ou en face-à-face, en situation ou non, interview ou observation, test d'un prototype ou test du produit existant, en groupe ou seul.</p> 
La seule importance pour nous est que vous passiez un bon moment et que vous soyez vous-même pendant l’étude :)
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="6">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Quels types d'études je vais pouvoir réaliser ?</span></Accordion.Header>
    <Accordion.Body>
    <p style={{display:'block'}}><span style={{fontWeight:'bold',color: '#007F8B'}}>Études en présentiel / En face à face :</span></p>

<p>Postule et participe à des missions près de chez toi! Tests utilisateurs, entretiens, ateliers (réunion en groupe).<br/>
Comme toujours, il n'y a pas de bonnes ou de mauvaises réponses, tu seras juste là pour partager ton avis et ton expérience. Ni plus, ni moins.</p>
<p style={{display:'block'}}><span style={{fontWeight:'bold',color: '#007F8B'}}>A distance / En ligne  :</span></p>

<p>Une multitude de missions disponibles en ligne te permettront de réaliser ton aventure sans sortir de chez toi (bien au chaud).<br/>
Questionnaire, sondage en ligne, avis consommateur, avis produit (réception du produit à domicile), tests utilisateurs à distance etc.</p>

<p style={{display:'block'}}><span style={{fontWeight:'bold',color: '#007F8B'}}>Donne ton avis sur un produit ou un service :</span></p> 

<p>Nos fonctionnalités de reconnaissance te permettront de donner ton avis à tout moment via l’application sur un produit ou un service que tu as utilisé.</p>
<p>L'objectif ?</p>
Accumuler des connaissances et des avis afin d'aider les concepteurs à améliorer les produits afin d'en faire bénéficier les consommateurs, comme toi!
 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="7">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Mes données sont-elles en sécurité ?</span></Accordion.Header>
    <Accordion.Body>
    Bien sûr, vos données sont protégées. UserAdventure suit la réglementation RGPD et vos données personnelles ne sont jamais transmises à des tiers. Vous pouvez également à tout instant nous demander de vous transmettre l'intégralité de vos données sur la plateforme, ainsi que nous demander de supprimer ces données de notre base.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="8">
    <Accordion.Header><span style={{fontWeight:500, fontSize:18}}>Je ne veux plus faire partie de l’aventure ?</span></Accordion.Header>
    <Accordion.Body>
    Vous ne souhaitez plus être sollicité(e) pour de futures études ? Ecrivez-nous par le <LinkContainer onClick={()=>{ window.scrollTo(0, 0)
                 ChangeActive('#/contact')}} style={{margin:0, padding:0}}  exact to={{ pathname:"/contact", state:{ version: '1' }}}>
                  <a>formulaire de contact</a>
                </LinkContainer> en nous indiquant "Je quitte l’aventure"  en sujet : nous effacerons vos données dans les plus brefs délais.
    </Accordion.Body>
    </Accordion.Item>
</Accordion>
</Col>
</Row>
<Col className="text-center" style={{fontSize: 20, paddingInline:30,paddingBlock:20, fontWeight:'bold', display:'none'}}><LinkContainer onClick={()=>{ window.scrollTo(0, 0)}} style={{margin:0, padding:0, color:'white', textDecorationLine:'underline'}}  exact to={{ pathname:"/faq", state:{ version: '1' }}}>
                  <Nav.Link>Tout la F.A.Q.</Nav.Link>
                </LinkContainer></Col>
    </Container>
    
    </>

  );
}

export default Home