import React, {useState, useMemo, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import {Fade, Flip} from 'react-reveal';
import Tada from 'react-reveal/Tada';
import Jump from 'react-reveal/Jump';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button, Form, Alert } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import { FaCoins } from "react-icons/fa";
import { IoIosTimer } from "react-icons/io";
import { RiCalendarCheckLine } from "react-icons/ri"
import { SiGooglemaps } from "react-icons/si";
import Wave from 'react-wavify'
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, useWindowDimensions} from 'react-native';

const Test = ({props, ChangeActive, sendColorbg, sendColor}) => {

  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const largeur = window.innerWidth;
    const color1 = "#007F8B";
    const color2 = "#988EFA";
    const URL = 'https://www.useradventure.net/'
    const [Nom, setNom] = useState('');
    const [Prenom, setPrenom] = useState('');
    const [Mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [sujet, setSujet] = useState('');
    const [msg, setMsg] = useState('');
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);
    const [DataEtude, setDataEtude] = useState([]);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
  
    useEffect(()=>{
      sendColorbg('white')
      sendColor("black")
      fetch('https://www.useradventure.net/json_get_react_futur_test.php')
      .then((response) => response.json())
      .then((response) => { 
    setDataEtude(response)
    console.log(response)
      })
      .catch((error) => alert(error))
      
      }, [props])

    const go = (idx, mail) => {
      if(mail !== undefined){
        
     console.log(mail)
     const Panier = DataEtude.slice();
     Panier.forEach(item => {
      if(item.Mail !== undefined){
      var position = item.Mail.indexOf("@");
      var reste = item.Mail.substr(-position);
      var posit = reste.indexOf(".");
      
      if(position !== -1 && posit !== -1  ){
          if(item.ID === idx.ID)
          {item.Mail = ""
                item.show = false
                item.show1 = true
            fetch(`https://www.useradventure.net/json_get_react_input_candidature.php`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',  // It can be used to overcome cors errors
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({email:mail, test:item.ID})
        })
          .catch((error) => console.error(error))
          }
        }else{
          item.show = true
          item.show1 = false
        }}
    })
   
     setDataEtude(Panier)
    }}

    
    
    const changeMail = (idx,index, email) => {

     const Panier = DataEtude.slice();
        Panier.forEach(item => {
          if(item.ID === idx.ID)
          {
            item.Mail = email
            item.show = false
            item.show1 = false
          }
      })
        setDataEtude(Panier)
    }

    return (
        
        <>
  <Container style={{background: `linear-gradient(to bottom,  ${color1} 0%,${color2} 100%)`, height:400 ,paddingTop:150}} fluid>
            <h1 style={{color:'white', textAlign:'center', paddingTop:60}}>Découvre les études à venir !</h1>
            
        </Container>
        <div style={{marginTop:-150}}>
        <Wave fill='white' style={{paddingInline:0, marginInline:0}} 
        paused={false}
        options={{
          height: 40,
          amplitude: 70,
          speed: 0.2,
          points: 2
        }}/>
        </div>


      <Container style={{marginTop:80, marginBottom:80}} fluid>  
 {/* <Col className="text-center" style={{fontSize: 30,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>Découvre les études à venir !</Col>
  <Col className="text-center" md={{span:10, offset: 1 }} style={{paddingInline:0, backgroundColor:'#febf01', height:1}}></Col> */}
{ DataEtude && DataEtude.map((item,index) => ( 
 
 
  item.Etat === '0' ? <Col className="text-center" md={{span:12, offset: 0 }} lg={{span:10, offset: 1 }} style={{paddingInline:0, backgroundColor:'white', borderRadius:15, boxShadow:  '5px 5px 20px rgb(0 127 139/ 99%)'}}>
   
 <Row style={{marginTop:80, padding:0}}>
   <Col xxl={4}>
   <Image src={item.Image} fluid  style={{ padding:0, justifySelf:'center',borderRadius:15}}/>
   </Col>
   <Col xxl={8} style={{ paddingBlock:width > 1440 ? 10:5,paddingInline:30}}>
   <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 28:26,color: '#007F8B', fontWeight:'bold'}}>{item.Titre}</Col>
   <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10}}>{item.Text1}</Col>
   <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16, marginTop:10}}><FaCoins style={{fontSize:30, color:'#febf01', width:20, marginRight:10}}/>Rémunération : <span style={{fontSize: width > 1440 ? 18:16,color: '#007F8B', fontWeight:'bold'}}>{item.Renumeration} €</span></Col>
 <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16}}><IoIosTimer style={{fontSize:30, color:'#febf01', width:20, marginRight:10}}/>Temps de l'étude : <span style={{fontSize: width > 1440 ? 18:16,color: '#007F8B', fontWeight:'bold'}}>{item.Temps}</span></Col>
 <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16}}><RiCalendarCheckLine style={{fontSize:30, color:'#febf01', width:20, marginRight:10}}/>Période estimée : <span style={{fontSize: width > 1440 ? 18:16,color: '#007F8B', fontWeight:'bold'}}>{item.Periode}</span></Col>
 <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16 ,marginBottom:20}}><SiGooglemaps style={{fontSize:30, color:'#febf01', width:20, marginRight:10}}/>Où : <span style={{fontSize: width > 1440 ? 18:16,color: '#007F8B', fontWeight:'bold'}}>{item.Moderation}</span></Col>
 <InputGroup className="mb-3">
        <Form.Control
        type="email"
        value={item.Mail}
        onChange={e =>{changeMail(item,index,e.target.value);setShow(false)}}
          placeholder="Mon Email"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
        <Button variant="warning" id="button-addon2" onClick={() => go(item,item.Mail)}>
          Ça m'intéresse
        </Button>
      </InputGroup>
   </Col>
 </Row>
{item.show && <Alert variant="danger">
 <Alert.Heading>Désolé votre adresse Mail n'est pas correct</Alert.Heading>
      </Alert>}
    {  item.show1 &&  <Alert  variant="success">
 <Alert.Heading>Merci pour votre candidature !!!</Alert.Heading>
      </Alert>}
 </Col> : undefined
 ))} 

 <Col className="text-center" style={{marginTop:80,fontSize: 30,color: '#007F8B',paddingInline:0, fontWeight:'bold'}}>Études terminées</Col>
  <Col className="text-center" md={{span:10, offset: 1 }} style={{paddingInline:0, backgroundColor:'#febf01', height:1}}></Col>
{ DataEtude && DataEtude.map((item,index) => ( 
 
 
  item.Etat === '1' ? <Col className="text-center applyZIndex" md={{span:12, offset: 0 }} lg={{span:10, offset: 1 }} style={{paddingInline:0, backgroundColor:'white', borderRadius:15, boxShadow:  '5px 5px 20px rgb(0 127 139/ 99%)'}}>
   
 <Row style={{marginTop:80, padding:0}}>
   <Col className="applyZIndex" xxl={4}>
   <Image  src={item.Image} fluid  style={{ padding:0, justifySelf:'center',borderRadius:15}}/>
   </Col>
   <Col xxl={8} style={{paddingBlock:width > 1440 ? 10:5}}>
   <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 28:26,color: '#007F8B',paddingInline:30, fontWeight:'bold'}}>{item.Titre}</Col>
   <Col className="text-center text-lg-start" style={{fontSize:  width > 1440 ? 18:16 ,marginTop:20,paddingInline:30}}>{item.Text1}</Col>
   <Col className="text-center text-lg-start" style={{fontSize:  width > 1440 ? 18:16 ,paddingInline:30, marginTop:20}}><FaCoins style={{fontSize:30, color:'#febf01', width:20, marginRight:10}}/>Rémunération : <span style={{fontSize: width > 1440 ? 18:16,color: '#007F8B', fontWeight:'bold'}}>{item.Renumeration} €</span></Col>
 <Col className="text-center text-lg-start" style={{fontSize:  width > 1440 ? 18:16 ,paddingInline:30}}><IoIosTimer style={{fontSize:30, color:'#febf01', width:20, marginRight:10}}/>Temps de l'étude : <span style={{fontSize: width > 1440 ? 18:16,color: '#007F8B', fontWeight:'bold'}}>{item.Temps}</span></Col>
 <Col className="text-center text-lg-start" style={{fontSize:  width > 1440 ? 18:16 ,paddingInline:30, marginBottom:0}}><RiCalendarCheckLine style={{fontSize:30, color:'#febf01', width:20, marginRight:10}}/>Période estimée : <span style={{fontSize: width > 1440 ? 18:16,color: '#007F8B', fontWeight:'bold'}}>{item.Periode}</span></Col>
 <Col className="text-center text-lg-start" style={{fontSize:  width > 1440 ? 18:16 ,paddingInline:30, marginBottom:20}}><SiGooglemaps style={{fontSize:30, color:'#febf01', width:20, marginRight:10}}/>Où : <span style={{fontSize: width > 1440 ? 18:16,color: '#007F8B', fontWeight:'bold'}}>{item.Moderation}</span></Col>
   </Col>
 </Row>
 
 </Col> : undefined
 ))} 

</Container>
      </>
    )
}

export default Test
